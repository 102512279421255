//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
import Auth from '@/mixins/Auth';
import Registration from '@/components/Auth/Registration';
import Login from '@/components/Auth/Login';
export default {
  name: 'AppAuth',
  mixins: [Base, Auth],
  components: {
    'app-registration': Registration,
    'app-login': Login
  },
  computed: {
    activeTab: {
      get: function get() {
        return this.$store.getters['modals/authModal'].activeTab;
      },
      set: function set(value) {
        this.$store.dispatch('modals/setAuthModal', {
          isOpen: true,
          activeTab: value
        });
      }
    }
  },
  methods: {
    close: function close() {
      if (this.warningMsg) this.closeWarningMsg();else if (this.step > 1) this.showWarningMsg();else {
        this.closeModal();
      }
    }
  },
  watch: {
    isDesktop: function isDesktop() {
      if (!this.showTabs) this.$store.commit('auth/setAuthTabs', true);
    }
  }
};